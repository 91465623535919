html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100vh;
  width: 100vw;
}
body {
  background-color: black;
}

#hydra-canvas {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -10;
}

.modal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .scene-modal {
    height: auto;
    width: 95%;
    border: 1px solid white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
  }

  .site-title {
    font-family: EnterTheGrid, monospace;
    color: white;
    font-size: 2em;
    padding: 0.5em;
  }
  .site-description {
    font-family: TerminalGrotesque;
    font-size: 0.9em;
    color: white;
    padding: 0 1em;
    a {
      color: white;
    }
  }

  .ticket-wrapper {
    display: inline;
  }
  .ticket-img {
    width: 40%;
    display: inline-block;
    padding: 0.5em;
  }
  .button-link {
    width: 35px;
    margin: 0 1em;
  }

  .credits-wrapper {
    text-align: end;
    padding-right: 1em;
    padding-bottom: 0.5em;
    .credits {
      font-family: TerminalGrotesque, monospace;
      color: white;
    }
  }
}

@media only screen and (min-width: 768px) {
  .modal-wrapper {
    .site-title {
      font-size: 2em;
    }
    .site-description {
      font-size: 1em;
    }

    .ticket-img {
      width: 25%;
    }
    .button-link {
      width: 35px;
      margin: 1em;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .modal-wrapper {
    .scene-modal {
      max-width: 800px;
    }
    .site-description {
      font-size: 1.2em;
    }

    .ticket-img {
      width: 30%;
    }
  }
}
